<template>
  <v-alert 
    v-if="get_printing_possible && !get_printserver_connected && company.printserver_id"
    color="warning"
    border="left"
    colored-border
    elevation="2"
    class="print-server-alert mb-4"
    dense
  >
    <v-row align="center" no-gutters>
      <v-col class="grow d-flex align-center">
        <v-icon left color="warning">mdi-printer-alert</v-icon>
        <span class="font-weight-medium">Printserver disconnected. Make sure Printserver is running before continuing.</span>
      </v-col>
      <v-col class="shrink ml-4">
        <v-btn
          v-if="get_user_scope('settings.printers')"
          color="warning"
          outlined
          rounded
          @click="$router.push('/auth/settings/printers')"
          class="alert-action-btn"
        >
          <v-icon left small>mdi-cog</v-icon>
          Printer Settings
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    
  },
  data(){
    return {
      
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_printserver_connected: 'warehouse/get_printserver_connected',
      get_user_scope: 'user/get_user_scope',
    }),
  },
  methods: {
    
  },
  mounted(){
    
  }
}
</script>

<style scoped>
.print-server-alert {
  animation: fadeIn 0.5s ease-out;
  border-width: 4px !important;
}

.alert-action-btn {
  transition: transform 0.2s ease;
}

.alert-action-btn:hover {
  transform: translateY(-2px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
