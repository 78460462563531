<template>
  <div class="dashboard-page">
    <v-container fluid class="py-6">
      <!-- Email Verification Alert -->
      <v-alert 
        v-if="auth && auth.email && !auth.emailVerified"
        color="warning"
        class="mb-6 email-verification-alert"
        elevation="2"
        border="left"
        dense
      >
        <v-row align="center">
          <v-col class="grow">
            Your email is not verified. Verify to be able to continue using {{ $appName }}.
          </v-col>
          <v-col class="shrink">
            <v-btn
              outlined
              @click="resend_email"
              v-if="!resent_email"
              :loading="resending_email"
            >
              Resend email
            </v-btn>
            <v-chip v-else color="success" outlined>
              Verification email resent
            </v-chip>
          </v-col>
        </v-row>
      </v-alert>

      <!-- PrintServer Alert -->
      <PrintServerAlert class="mb-6" />

      <!-- Store Permissions Alerts -->
      <template v-for="(store, index) in company.data_sources">
        <v-alert
          :key="index"
          v-if="!store.shopify_scopes_last_updated_at || new Date(store.shopify_scopes_last_updated_at).getTime() < new Date(shopify_scopes_last_updated_at).getTime()"
          color="warning"
          class="mb-6 store-alert"
          elevation="2"
          border="left"
          dense
        >
          <v-row align="center">
            <v-col class="grow">
              {{ store.name }} needs to update permissions with Shopify to continue work as expected.
            </v-col>
            <v-col class="shrink">
              <v-btn
                outlined
                @click="update_permissions({store, index})"
                v-if="store.parent_company_id === company.id"
                :loading="updating_permissions"
              >
                Update permissions
              </v-btn>
              <v-btn
                v-else
                outlined
                :href="`mailto:${store.email}`"
              >
                Contact owner
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>

      <!-- Main Dashboard Content -->
      <v-row>
        <!-- Initial Setup Choice -->
        <v-col cols="12" v-if="is_first_initialization && is_fulfiller">
          <v-card class="setup-choice-card" elevation="3" rounded="lg">
            <v-card-text class="text-center py-10">
              <h2 class="text-h4 font-weight-bold mb-10">How do you plan on using {{ $appName }}?</h2>
              
              <v-btn 
                x-large
                color="primary"
                elevation="2"
                rounded
                class="px-6 mb-8 setup-btn"
                @click="$store.commit('app/SET_STATE', { user_decided_initial_purpose: true })"
              >
                <v-icon left>mdi-package-variant</v-icon>
                Fulfill Orders Myself
              </v-btn>
              
              <div class="d-flex align-center my-6">
                <v-divider class="mx-4"></v-divider>
                <span class="text-subtitle-1 grey--text">OR</span>
                <v-divider class="mx-4"></v-divider>
              </div>
              
              <v-btn
                x-large
                color="secondary"
                elevation="2"
                rounded
                class="px-6 setup-btn"
                @click="show_transfer_dialog = true"
              >
                <v-icon left>mdi-account-group</v-icon>
                Connect with a 3PL partner
              </v-btn>
            </v-card-text>
          </v-card>

          <!-- 3PL Partner Dialog -->
          <v-dialog
            v-model="show_transfer_dialog"
            max-width="500px"
            class="pa-4"
          >
            <v-card rounded="lg" elevation="4">
              <v-card-title class="primary white--text">
                <v-row dense>
                  <v-col class="grow">Connect with 3PL Partner</v-col>
                  <v-col class="shrink">
                    <v-btn
                      icon
                      @click="show_transfer_dialog = false"
                    >
                      <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle class="mt-4 mb-0">
                Paste the 3PL Partner ID supplied by your 3PL Partner
              </v-card-subtitle>
              <v-card-text>
                <v-text-field
                  rounded
                  outlined
                  label="3PL Partner ID"
                  v-model="logistics_connect_id"
                  @input="on_search_logistics_partner"
                  :loading="on_loading_logistics_partner"
                  prepend-inner-icon="mdi-identifier"
                >
                </v-text-field>
                <v-text-field
                  rounded
                  outlined
                  readonly
                  label="3PL Company"
                  :disabled="!logistics_partner"
                  :value="logistics_partner && logistics_partner.name || ''"
                  :loading="on_loading_logistics_partner"
                  prepend-inner-icon="mdi-domain"
                >
                </v-text-field>
                <v-alert
                  type="warning"
                  dense
                  v-if="logistics_partner && !logistics_partner.has_connects"
                >
                  {{ logistics_partner.name }} needs to get more 3PL Connects to be able to connect with you.
                </v-alert>
                <v-btn
                  color="primary"
                  rounded
                  block
                  class="mt-4"
                  :disabled="!logistics_partner || !logistics_partner.has_connects"
                  :loading="on_connecting_with_logistics_partner"
                  @click="on_connect_with_logistics_partner"
                >
                  Connect
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>

        <!-- Onboarding Card -->
        <v-col cols="12" v-else-if="is_fulfiller && get_onboarding_tasks.some(t => !t.done) && (user.authenticator === 'shopify' || is_external_company_owner)">
          <OnboardingCard />
        </v-col>

        <!-- Dashboard Cards -->
        <template v-if="!is_first_initialization || !is_fulfiller">
          <!-- No Warehouse or Store Connections -->
          <v-col cols="12" md="6" v-if="!company.id">
            <v-card 
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <v-card-title class="text-h5 font-weight-medium">
                <v-icon left color="warning">mdi-alert-circle-outline</v-icon>
                You have no warehouse or store connections
              </v-card-title>
              <v-card-text class="text-body-1">
                Connect a Shopify Store through Shopify App Store and add it to your account to get started.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  href="https://apps.shopify.com/"
                  target="_blank"
                >
                  <v-icon left>mdi-open-in-new</v-icon>
                  Shopify App Store
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Fulfillment Location Selection -->
          <template v-else-if="is_fulfiller && get_user_scope('settings.stores')">
            <v-col cols="12" md="6" v-for="ds in company.data_sources.filter(ds => !ds.location_id)" :key="ds.id">
              <v-card
                class="dashboard-card"
                elevation="3"
                rounded="lg"
              >
                <v-card-title class="text-h6 font-weight-medium">
                  <v-icon left color="primary">mdi-map-marker-question</v-icon>
                  Which fulfillment location do you wish to use for {{ ds.name }}?
                </v-card-title>
                <v-card-text>
                  <FulfillmentLocationSelect 
                    :store="ds"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </template>

          <!-- Unmapped Products Card -->
          <v-col cols="12" md="6" v-if="is_fulfiller && get_user_scope('warehouse')">
            <v-card
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <template v-if="loading_products">
                <v-card-title class="text-h6 font-weight-medium">
                  <v-icon left color="info">mdi-sync</v-icon>
                  Products are still syncing
                </v-card-title>
                <v-card-text class="d-flex justify-center align-center pa-6">
                  <v-progress-circular indeterminate size="64" width="5" color="primary"></v-progress-circular>
                </v-card-text>
              </template>
              <template v-else-if="get_amount_unmapped_products">
                <v-card-title class="text-h6 font-weight-medium">
                  <v-icon left color="warning">mdi-map-marker-off</v-icon>
                  You have {{ get_amount_unmapped_products }} unmapped products
                </v-card-title>
                <v-card-text class="text-body-1">
                  Map them out now to make the fulfillment process more secure and efficient.
                </v-card-text>
                <v-card-actions>
                  <v-btn 
                    @click="$router.push('/auth/warehouse')" 
                    color="primary"
                    rounded
                    elevation="1"
                  >
                    <v-icon left>mdi-warehouse</v-icon>
                    Warehouse layout
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-else>
                <v-card-title class="text-h6 font-weight-medium">
                  <v-icon left color="success">mdi-check-circle</v-icon>
                  All your products are mapped out!
                </v-card-title>
                <v-card-text class="text-body-1">
                  Good job! Make sure to keep the warehouse layout correct.
                </v-card-text>
                <v-card-actions>
                  <v-btn 
                    @click="$router.push('/auth/warehouse')" 
                    color="primary"
                    text
                  >
                    <v-icon left>mdi-warehouse</v-icon>
                    View warehouse
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>

          <!-- Orders to Fulfill Card -->
          <v-col cols="12" md="6" v-if="get_user_scope('fulfill') && order.order_count && is_fulfiller">
            <v-card
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <v-card-title class="text-h6 font-weight-medium">
                <v-icon left color="primary">mdi-package-variant</v-icon>
                {{ order.order_count }} orders to fulfill
              </v-card-title>
              <v-card-text class="text-body-1">
                Get going right away!
              </v-card-text>
              <v-card-actions>
                <v-btn 
                  @click="$router.push('/auth/fulfill')" 
                  color="primary"
                  rounded
                  elevation="1"
                >
                  <v-icon left>mdi-package-variant</v-icon>
                  Fulfill orders
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Store Being Fulfilled Card -->
          <v-col cols="12" v-if="!is_fulfiller && company.data_sources.length">
            <v-card
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <v-card-title class="text-h6 font-weight-medium">
                <v-icon left color="info">mdi-information</v-icon>
                Your store is being fulfilled by {{ company.data_sources[0].fulfilling_company_name }}
              </v-card-title>
              <v-card-text class="text-body-1">
                To change or edit this, view Settings > Stores
              </v-card-text>
              <v-card-actions>
                <v-btn 
                  @click="$router.push('/auth/settings/stores')" 
                  color="primary"
                  rounded
                  elevation="1"
                >
                  <v-icon left>mdi-cog</v-icon>
                  Settings
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Blocked Items Card -->
          <v-col cols="12" md="6" v-if="get_user_scope('logs') && blocked_items.length">
            <v-card
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <v-card-title class="text-h6 font-weight-medium">
                <v-icon left color="error">mdi-block-helper</v-icon>
                {{ blocked_items.length }} items are blocked
              </v-card-title>
              <v-card-text>
                <v-list dense class="blocked-items-list">
                  <v-list-item two-line v-for="(item, index) in blocked_items.slice(0, 3)" :key="`list-${index}`" class="pl-0">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{ get_product(item.item_uuid) && get_product(item.item_uuid).title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.blocked_at | DateFilter }}: {{ item.reason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-btn 
                  @click="$router.push('/auth/logs')" 
                  color="primary"
                  rounded
                  elevation="1"
                >
                  <v-icon left>mdi-eye</v-icon>
                  Review all blocks
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Blocked Orders Card -->
          <v-col cols="12" md="6" v-if="get_user_scope('logs') && blocked_orders.length">
            <v-card
              class="dashboard-card"
              elevation="3"
              rounded="lg"
            >
              <v-card-title class="text-h6 font-weight-medium">
                <v-icon left color="error">mdi-block-helper</v-icon>
                {{ blocked_orders.length }} orders are blocked
              </v-card-title>
              <v-card-text>
                <v-list dense class="blocked-orders-list">
                  <v-list-item two-line v-for="(order, index) in blocked_orders.slice(0, 3)" :key="`orderlist-${index}`" class="pl-0">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{ order.header }}</v-list-item-title>
                      <v-list-item-subtitle>{{ order.blocked_at | DateFilter }}: {{ order.reason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-btn 
                  @click="$router.push('/auth/logs')" 
                  color="primary"
                  rounded
                  elevation="1"
                >
                  <v-icon left>mdi-eye</v-icon>
                  Review all blocks
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Avatar Certificates Card -->
          <v-col cols="12" v-if="company.custom_scripts.indexOf('avatar') !== -1">
            <AvatarCertificatesCard
              class="dashboard-card"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { uid } from 'uid'
import { DateFilter } from '@/utils/filters'
import AvatarCertificatesCard from '@/components/custom/AvatarCertificatesCard'
import PrintServerAlert from '@/components/PrintServerAlert'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'
import OnboardingCard from '@/components/OnboardingCard'

export default {
  components: {
    AvatarCertificatesCard,
    PrintServerAlert,
    FulfillmentLocationSelect,
    OnboardingCard,
  },
  computed: {
    ...mapGetters({
      get_user_scope: 'user/get_user_scope',
      get_amount_unmapped_products: 'warehouse/get_amount_unmapped_products',
      get_product: 'product/get_product',
      get_onboarding_tasks: 'app/get_onboarding_tasks',
      is_first_initialization: 'app/is_first_initialization',
      is_fulfiller: 'app/is_fulfiller',
      is_external_company_owner: 'app/is_external_company_owner',
    }),
    ...mapState({
      auth: state=> state.user.user,
      user: state => state.user.userProfile,
      company: state => state.warehouse.company,
      order: state => state.order,
      loading_products: state => state.product.loading_products,
      shopify_scopes_last_updated_at: s => s.app.config.shopify_scopes_last_updated_at,
    }),
    blocked_items(){
      return this.company.data_sources.reduce((tot, ds) => !ds.blocked_items ? tot : ds.blocked_items.concat(tot), []).sort((a, b) => new Date(b.blocked_at).getTime() - new Date(a.blocked_at).getTime())
    },
    blocked_orders(){
      return this.company.data_sources.reduce((tot, ds) => !ds.blocked_orders ? tot : ds.blocked_orders.concat(tot), []).sort((a, b) => new Date(b.blocked_at).getTime() - new Date(a.blocked_at).getTime())
    },
  },
  filters: {
    DateFilter,
  },
  data() {
    return {
      resent_email: false,
      resending_email: false,
      updating_permissions: false,
      updated: [],
      dialog: false,
      show_transfer_dialog: false,
      logistics_connect_id: '',
      on_loading_logistics_partner: false,
      logistics_partner: null,
      on_connecting_with_logistics_partner: false,
    }
  },
  methods: {
    async on_connect_with_logistics_partner(){
      this.on_connecting_with_logistics_partner = true
      const get_logistics_partner = this.$functions.httpsCallable('get_logistics_partner')
      await Promise.all([
        this.$store.dispatch('warehouse/save_data_source', {
          id: this.company.data_sources[0].id,
          fulfilling_company_id: this.logistics_connect_id,
          fulfilling_company_name: this.logistics_partner.name,
          allow_transfer: true
        }),
        get_logistics_partner({ logistics_connect_id: this.logistics_connect_id, ds_id: this.company.data_sources[0].id }).then(r => r.data)
      ])
      if(this.company.data_sources[0].auto_carrier_services?.active) await this.$store.dispatch('warehouse/delete_auto_carrier', { 
          ds_id: this.company.data_sources[0].id,
        });
      this.on_connecting_with_logistics_partner = false
      this.show_transfer_dialog = false
    },
    async on_search_logistics_partner(){
      if(this.logistics_connect_id.length !== ('418e4f29023').length) return
      this.on_loading_logistics_partner = true
      try {
        const get_logistics_partner = this.$functions.httpsCallable('get_logistics_partner')
        const logistics_partner = await get_logistics_partner({ logistics_connect_id: this.logistics_connect_id }).then(r => r.data)
        this.logistics_partner = logistics_partner
        console.log(logistics_partner)
      } catch (e){
        console.log(e)
      }
      this.on_loading_logistics_partner = false
    },
    async update_permissions({store, index}){
      this.updating_permissions = true
      const state = uid()
      firebase.firestore().collection('data_sources').doc(store.id).set({ shopify_last_known_state_uid: state }, {merge: true })
      const url = `https://${store.myshopify_name}/admin/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_CLIENT_ID}&scope=${process.env.VUE_APP_SHOPIFY_SCOPES}&redirect_uri=${window.location.origin}/&state=${state}`

      window.open(url, '_top')
      this.updating_permissions = false
      this.updated.push(index)
    },
    async resend_email(){
      this.resending_email = true
      await firebase.auth().currentUser.sendEmailVerification()
      this.resent_email = true
      this.resending_email = false
    }
  },
  mounted(){
    
  },
}
</script>

<style lang="scss" scoped>
.dashboard-page {
  background-color: #f6f6f7;
  min-height: 100%;
}

.email-verification-alert,
.store-alert {
  animation: fadeIn 0.6s ease-out;
}

.dashboard-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  animation: fadeIn 0.8s ease-out;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
  
  .v-card__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
  
  .v-card__text {
    padding-top: 16px;
  }
  
  .v-card__actions {
    padding: 16px;
  }
}

.setup-choice-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  
  .setup-btn {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.blocked-items-list,
.blocked-orders-list {
  background-color: transparent;
  
  .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 0;
    
    &:last-child {
      border-bottom: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .dashboard-card {
    margin-bottom: 16px;
  }
}
</style>
