<template>
  <div class="fulfillment-location-select">
    <!-- Location Selector -->
    <v-select
      v-model="location"
      :items="store.locations"
      item-text="name"
      return-object
      label="Select location"
      outlined
      rounded
      dense
      prepend-inner-icon="mdi-map-marker"
      :error-messages="!location && touched ? ['Please select a location'] : []"
      @change="() => {touched = true; (uses_shopify_labels = can_use_shopify_labels)}"
      class="mb-4"
    />
    
    <!-- Shopify Labels Selector (Commented out but styled for future use) -->
    <!-- <v-checkbox
      label="Uses Shopify Labels (beta, US store & locations only)"
      v-model="uses_shopify_labels"
      :disabled="!can_use_shopify_labels"
      @change="touched=true"
      hide-details
      class="mb-4"
    /> -->
    
    <!-- Label Size Selector -->
    <v-select
      v-if="uses_shopify_labels"
      label="Which is your default label size?"
      v-model="label_size"
      :items="label_formats"
      item-text="description"
      item-value="title"
      outlined
      rounded
      dense
      prepend-inner-icon="mdi-label"
      @change="touched=true"
      class="mb-4"
    />
    
    <!-- Info Alert (Commented out but styled for future use) -->
    <!-- <v-alert
      v-if="uses_shopify_labels && !store.approved_shopify_beta"
      type="info"
      outlined
      dense
      class="mb-4 info-alert"
    >
      <v-icon left color="info">mdi-information</v-icon>
      Shopify Labels is currently in beta mode. When you save, we will be notified and will reach out to Shopify to open up your store for buying Shopify Labels externally, which may take up to two working days. We will contact you via email once you're approved for beta testing.
    </v-alert> -->
    
    <!-- Save Button -->
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        rounded
        elevation="2"
        @click="save_location"
        :disabled="!location || !touched"
        :loading="saving"
        class="save-btn px-6"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save location
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    store: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      location: null,
      uses_shopify_labels: false,
      touched: false,
      label_size: null,
      saving: false
    }
  },
  computed: {
    ...mapState({
      company: (state) => state.warehouse.company,
      label_formats: state => state.app.config.label_formats.shopify
    }),
    can_use_shopify_labels() {
      return false
      // this.store?.shop_country_code === 'US' && this.location?.country_code === 'US'
    }
  },
  methods: {
    async save_location() {
      if (!this.location) return;
      
      this.saving = true;
      
      try {
        if (this.uses_shopify_labels) {
          this.$store.dispatch('app/submit_log', {
            message: `STORE ${this.store.name}, ID ${this.store.id}, ${this.store.base_url} REQUESTED SHOPIFY LABELS ON ${new Date().toISOString()}`
          });
          
          const carriers = this.company.carriers;
          const new_carrier = {
            id: 'shopify',
            type: 'shopify',
            default_label_type: this.label_size
          };
          
          if (carriers.some(c => c.id === 'shopify')) {
            carriers[carriers.findIndex(c => c.id === 'shopify')] = { ...new_carrier };
          } else {
            carriers.push({ ...new_carrier });
          }
          
          this.$store.commit('warehouse/SET_COMPANY', { carriers });
          await this.$store.dispatch('warehouse/save_company', ['carriers']);
        } else if (
          !this.uses_shopify_labels && 
          !this.company.data_sources.filter(ds => ds.id !== this.store.id).some(ds => ds.uses_shopify_labels) && 
          this.company.carriers.some(c => c.type === 'shopify')
        ) {
          this.$store.commit('warehouse/SET_COMPANY', { 
            carriers: this.company.carriers.filter(c => c.type !== 'shopify') 
          });
          await this.$store.dispatch('warehouse/save_company', ['carriers']);
        }
        
        await this.$store.dispatch('warehouse/save_data_source', {
          id: this.store.id,
          location_id: this.location.id,
          location_country_code: this.location.country_code,
          uses_shopify_labels: this.uses_shopify_labels,
        });
        
        this.touched = false;
        
        // Show success message
        this.$store.commit('app/SET_SNACK_BAR', {
          text: `Location saved for ${this.store.name}`,
          type: 'success'
        });
      } catch (error) {
        console.error('Error saving location:', error);
        
        // Show error message
        this.$store.commit('app/SET_SNACK_BAR', {
          text: 'Failed to save location',
          type: 'error'
        });
      } finally {
        this.saving = false;
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('warehouse/get_fulfillment_locations', this.store.id);
      this.location = this.store.locations.find(i => i.id === this.store.location_id) || null;
      this.uses_shopify_labels = this.store.uses_shopify_labels;
      this.label_size = this.company.carriers.find(i => i.id === 'shopify') && 
                        this.company.carriers.find(i => i.id === 'shopify').default_label_type;
    } catch (error) {
      console.error('Error loading fulfillment locations:', error);
    }
  }
}
</script>

<style scoped>
.fulfillment-location-select {
  animation: fadeIn 0.4s ease-out;
}

.save-btn {
  transition: transform 0.2s ease;
}

.save-btn:hover:not(:disabled) {
  transform: translateY(-2px);
}

.info-alert {
  border-radius: 8px;
  font-size: 0.9rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
