<template>
  <v-container class="onboarding-container pa-0">
    <!-- Fulfillment Location Dialog -->
    <v-dialog
      v-model="dialog"
      width="550"
      content-class="fulfillment-dialog"
    >
      <v-card rounded="lg" elevation="8">
        <v-card-title class="dialog-header primary white--text">
          <v-icon left color="white">mdi-map-marker</v-icon>
          Select fulfillment location
        </v-card-title>
        
        <v-card-text class="pa-6">
          <div v-for="(ds, i) in data_sources" :key="`dschoose-${i}`" class="mb-4">
            <h4 class="text-subtitle-1 font-weight-medium mb-3">
              Which fulfillment location do you wish to use for {{ ds.name }}?
            </h4>
            <FulfillmentLocationSelect 
              :store="ds"
            />
            <v-divider class="my-4" v-if="i < data_sources.length - 1"></v-divider>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            elevation="2"
            @click="dialog = false"
            class="px-6"
          >
            <v-icon left>mdi-check</v-icon>
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Onboarding Header -->
    <v-card class="onboarding-card mb-6" rounded="lg" elevation="3">
      <v-card-text class="pa-6">
        <div class="text-overline text-uppercase primary--text font-weight-medium mb-2">
          Manage warehouse, shipping, staff and highly efficient picking
        </div>
        <h2 class="text-h4 font-weight-bold mb-2">
          Your personal Fulfillment Center
        </h2>
        <p class="text-body-1">
          Complete these steps to set up your fulfillment center and start processing orders efficiently.
        </p>
      </v-card-text>
    </v-card>

    <!-- Onboarding Timeline -->
    <v-card class="timeline-card" rounded="lg" elevation="3">
      <v-card-text class="pa-6">
        <v-timeline dense class="onboarding-timeline">
          <v-timeline-item
            v-for="(task, index) in onboarding_tasks"
            :key="`task-${index}`"
            :color="getTaskColor(task)"
            :icon="getTaskIcon(task)"
            fill-dot
            class="timeline-item mb-3"
          >
            <div 
              @click="task.current && !task.done ? handle_button(task.button_action) : null"
              :class="['task-content d-flex align-center', {'task-clickable': task.current && !task.done}]"
            >
              <div class="task-info flex-grow-1">
                <div class="d-flex align-center mb-1">
                  <h3 class="text-subtitle-1 font-weight-medium mb-0">{{ task.header }}</h3>
                  <v-chip 
                    x-small 
                    :color="task.done ? 'success' : task.current ? 'primary' : 'grey'" 
                    text-color="white"
                    class="ml-3"
                    v-if="task.done || task.current"
                  >
                    {{ task.done ? 'Completed' : 'Current' }}
                  </v-chip>
                </div>
                <p class="text-body-2 grey--text text--darken-1 mb-0">
                  {{ task.description }}
                </p>
              </div>
              
              <div class="task-action ml-4">
                <v-btn 
                  v-if="task.current && !task.done"
                  color="primary" 
                  rounded
                  elevation="2"
                  @click.stop="handle_button(task.button_action)"
                  class="action-button"
                >
                  <v-icon left size="18">{{ getActionIcon(task.button_action) }}</v-icon>
                  {{ task.button_text }}
                </v-btn>
                <v-icon v-else-if="task.done" color="success">mdi-check-circle</v-icon>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'

export default {
  components: {
    FulfillmentLocationSelect
  },
  computed: {
    ...mapGetters({
      onboarding_tasks: 'app/get_onboarding_tasks',
    }),
    ...mapState({
      data_sources: state => state.warehouse.company.data_sources
    })
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    handle_button(action) {
      if (action === 'carrier') this.$router.push('/auth/settings/carriers?showDialog=true')
      else if (action === 'add_stores') this.$router.push('/auth/settings/stores')
      else if (action === 'shipping') this.$router.push('/auth/settings/stores?showDialog=true')
      else if (action === 'plans') this.$router.push('/auth/plans')
      else if (action === 'warehouse') this.$router.push('/auth/warehouse')
      else if (action === 'printers') this.$router.push('/auth/settings/printers')
      else if (action === 'staff') this.$router.push('/auth/settings/users')
      else if (action === 'fulfillment_location') this.dialog = true
    },
    getTaskColor(task) {
      if (task.done) return 'success'
      if (task.current) return 'primary'
      return 'grey lighten-1'
    },
    getTaskIcon(task) {
      if (task.done) return 'mdi-check-circle'
      if (task.current) return 'mdi-arrow-right-circle'
      return 'mdi-circle-outline'
    },
    getActionIcon(action) {
      switch (action) {
        case 'carrier': return 'mdi-truck'
        case 'shipping': return 'mdi-package-variant'
        case 'plans': return 'mdi-shield-lock-open'
        case 'warehouse': return 'mdi-warehouse'
        case 'printers': return 'mdi-printer-wireless'
        case 'staff': return 'mdi-account-multiple'
        case 'fulfillment_location': return 'mdi-map-marker'
        default: return 'mdi-arrow-right'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-container {
  max-width: 900px;
}

.onboarding-card, 
.timeline-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1) !important;
  }
}

.onboarding-timeline {
  padding-top: 8px;
  
  .timeline-item {
    margin-bottom: 16px;
  }
}

.task-content {
  padding: 8px 12px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.task-clickable {
  cursor: pointer;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
}

.action-button {
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.dialog-header {
  padding: 16px 20px;
}

.fulfillment-dialog {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
